const qs = sel => document.querySelector(sel);
const qsa = sel => document.querySelectorAll(sel);
//=require scripts/config.js
$(function() {
    svg4everybody();

    $(document).on('click', '.js-sidebar-toggler', function(event) {
    	$(this).closest('.js-sidebar').toggleClass('is-expand');
    });


    $(document).on('click', '.js-menu-toggler', function(event) {
    	$('.js-menu-toggler').toggleClass('is-active');
    	$(this).closest('.js-menu-wrapper').toggleClass('is-active');
    });


    
    

});
